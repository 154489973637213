.container {
    margin-top: 0px;
}
    #busStops {
        width: 50%;
        height: 80px;
        position: relative;
        margin-bottom: 8px;
        margin-left: -140px;
      }
  
      #road {
        width: 600px;
        height: 4px;
        position: absolute;
        bottom: -4px;
        background-color: black;
      }
  
      #busMirror {
        width: 30px;
        height: 30px;
        background-color: black;
        /*background-size: cover;*/
        clip-path: polygon(21% 10%, 15% 0%, 23% 0, 35% 21%, 43% 29%, 47% 40%, 49% 53%, 47% 64%, 39% 73%, 32% 26%);
        left: 166px;
        bottom: 18px;
        border-radius: 2px;
        position: absolute;
        animation-name: busMirror;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        /* animation-play-state: paused; */
        /* animation-play-state: running; */
      }
      
      @keyframes busMirror {
        0% {
          left: 166px;
          bottom: 18px;
        }
  
        20% {
          transform-origin: top;
          transform: skew(0deg, 0deg);
        }
  
        23% {
          transform-origin: top;
          transform: skew(-40deg, 0deg);
        }
  
        30% {
          left: 467px;
          bottom: 18px;
        }
  
        50% {
          left: 487px;
          bottom: 18px;
        }
  
        53% {
          transform-origin: top;
          transform: skew(-5deg, 0deg);
        }
  
        60% {
          left: 467px;
          bottom: 18px;
        }
  
        63% {
          transform-origin: top;
          transform: skew(-35deg, 0deg);
        }
  
        65% {
          left: 467px;
          bottom: 14px;
        }
  
        70% {
          transform-origin: top;
          transform: skew(-15deg, 0deg);
        }
  
        74% {
          left: 467px;
          bottom: 14px;
        }
  
        76% {
          left: 472px;
          bottom: -7px;
        }
  
        88% {
          transform-origin: bottom;
          transform: rotate(-80deg);
        }
  
        90% {
          left: 480px;
          bottom: 4px;
        }
  
        100% {
          transform-origin: bottom;
          transform: rotate(-80deg);
        }
  
        100% {
          left: 480px;
          bottom: 4px;
        }
      }
  
     #bus {
        width: 180px;
        height: 70px;
        /*clip-path: polygon(15% 80%, 20% 80%, 23% 70%, 31% 71%, 34% 81%, 46% 81%, 60% 81%, 62% 71%, 71% 72%, 73% 82%, 90% 82%, 91% 75%, 89% 72%, 89% 55%, 87% 27%, 76% 25%, 74% 22%, 61% 21%, 59% 24%, 30% 25%, 29% 21%, 19% 20%, 15% 25%, 7% 25%, 3% 31%, 2% 57%, 3% 73%, 2% 80%, 3% 81%, 6% 81%, 11% 81%);*/
  
        clip-path: polygon(71% 84%, 72% 77%, 79% 79%, 81% 95%, 82% 95%, 82% 41%, 88% 41%, 88% 94%, 89% 94%, 89% 41%, 95% 41%, 95% 95%, 98% 94%, 98% 87%, 97% 85%, 97% 71%, 96% 41%, 96% 35%, 96% 27%, 82% 24%, 80% 22%, 75% 21%, 74% 24%, 24% 22%, 23% 19%, 17% 18%, 16% 21%, 7% 23%, 5% 26%, 5% 32%, 15% 35%, 15% 63%, 8% 62%, 8% 40%, 9% 35%, 5% 33%, 5% 64%, 5% 77%, 4% 81%, 5% 90%, 13% 93%, 16% 93%, 16% 40%, 21% 40%, 21% 92%, 22% 92%, 22% 40%, 28% 40%, 28% 93%, 30% 93%, 32% 77%, 38% 78%, 40% 94%, 48% 94%, 47% 70%, 30% 70%, 30% 35%, 46% 35%, 46% 69%, 48% 94%, 48% 40%, 54% 40%, 54% 95%, 55% 95%, 55% 40%, 61% 40%, 61% 94%, 62% 94%, 62% 35%, 72% 35%, 72% 67%, 63% 67%, 73% 67%, 73% 35%, 81% 36%, 81% 67%, 75% 67%, 62% 68%, 62% 94%, 71% 94%);
  
        border-radius: 2px;
        background-color: green;
        position: absolute;
        bottom: 0px;
        animation-name: bus;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes bus {
        0% {
          left: 0px;
          bottom: 0px;
        }
  
        30% {
          left: 300px;
          bottom: 0px;
        }
  
        50% {
          left: 320px;
          bottom: 0px;
        }
  
        60% {
          left: 300px;
          bottom: 0px;
        }
  
        65% {
          left: 300px;
          bottom: -4px;
        }
  
        100% {
          left: 300px;
          bottom: -4px;
        }
      }
  
      #busWheelRear {
        border-radius: 25px;
        border: 4px solid black;
        width: 13px;
        height: 13px;
        position: absolute;
        bottom: 0px;
        left: 56px;
        animation-name: busWheelRear;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        /* animation-play-state: paused; */
        animation-play-state: running;
      }
  
      @keyframes busWheelRear {
        0% {
          left: 56px;
        }
  
        30% {
          left: 357px;
        }
  
        50% {
          left: 376px;
        }
  
        60% {
          left: 356px;
        }
  
        65% {
          left: 356px;
        }
  
        100% {
          left: 356px;
        }
      }
  
      #busWheelFront {
        border-radius: 25px;
        border: 4px solid black;
        width: 13px;
        height: 13px;
        position: absolute;
        bottom: 0px;
        left: 130px;
        animation-name: busWheelFront;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes busWheelFront {
        0% {
          left: 130px;
        }
  
        30% {
          left: 429px;
        }
  
        50% {
          left: 450px;
        }
  
        60% {
          left: 430px;
        }
  
        65% {
          left: 430px;
        }
  
        100% {
          left: 430px;
        }
      }
  
      #busSign {
        width: 3px;
        height: 70px;
        background-color: rgb(2, 105, 2);
        position: absolute;
        left: 450px;
        bottom: 0px;
        animation-name: busSign;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes busSign {
        20% {
          transform: skew(-1deg, 0deg);
        }
  
        23% {
          transform: skew(-22deg, 0deg);
        }
  
        100% {
          transform: skew(-22deg, 0deg);
        }
      }
  
      #busSignUp {
        width: 25px;
        height: 25px;
        background-color: goldenrod;
        color: green;
        border: green;
        border-style: solid;
        font-size: 15px;
        text-align: center;
        border-radius: 100%;
        position: absolute;
        left: 428px;
        bottom: 55px;
        animation-name: busSignUp;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes busSignUp {
        20% {
          left: 428px;
          bottom: 55px;
        }
  
        22% {
          left: 439px;
          bottom: 57px;
        }
  
        100% {
          left: 439px;
          bottom: 57px;
        }
      }
    