.welcome {
    display: flex;
    justify-content: flex-end;
    color: rgb(78, 78, 78);
    height: 18px;
    /* background-color:#060b26; */
  }
.logout-icon {
    color: rgb(78, 78, 78);
    margin: 0rem 0.2rem 0rem 0.4rem;
  }
  /* #navbar-flash-db {
    background-color: #181818;
    
    height: 35px;
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    animation-name: move_flash_db;
    margin-left: 0px;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-delay: 2s;
    z-index: 34;
    animation-fill-mode: forwards;
} */
/* @keyframes move_flash_db {
    0% {transform: scaleY(1);}
    90% {transform: scaleY(0.0);}
    100% {transform: scaleY(0.0);}
} */
#logdb {
    position: absolute;
    padding-left: 90%;
    padding-top: 6px;
}
.navbar {
    background-color: #181818;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 0px;
    border-top: 4px solid;
    /* border-top-color: rgb(99, 44, 228); */
    border-top-color: #27cdff;
}
/* .navbar-flash {
        background-color: rgb(255, 255, 255);
        background-image: linear-gradient(to right,            
            #27cdff 25%,
            rgba(255, 255, 255, 0.1) 50%,            
            #27cdff 75%);
        color: white;
        height: 4px;
        width: 90%;
        margin-top: -40px;
        margin-left: -130px;
        animation-name: move_flash;
        animation-duration: 30s;
        animation-iteration-count: infinite;
}
@keyframes move_flash {
    0% {transform: translateX(0)}
  20% {transform: translateX(250%)}
  40% {transform: translateX(0)}
  49% {transform: translateX(0)}
  69% {transform: translateX(250%)}
  89% {transform: translateX(0)}
} */

.menu-bars {
    margin: 0.4rem 0rem 0rem 1rem;
    font-size: 2rem;
    background: none;
    z-index:30;
}

.nav-menu {
    background-color: #181818;
    opacity: 0.97;
    width: 200px;
    height: 90vh;
    display: flex;
    justify-content: left;
    position: fixed;
    top: 69px;
    left: -100%;
    transition: 850ms;
    z-index:10;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}
.nav-text svg {
    margin: 10px 10px 5px 10px;
}
.nav-text {
    display: flex;
    font-size: 18px;
    list-style: none;
    color: white;
    text-decoration: none;
    margin-left: -40px;
}
.nav-text.active {
    width: 200px; height: 40px;
    background: rgb(41, 41, 41);
    background-size: 100% 100%;
    /* border-left: 5px solid #632ce4; */
    border-left: 5px solid #27cdff
}
/* .nav-text-flash{
    position: relative;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(to right,
        rgba(41, 41, 41, .9) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(41, 41, 41, .9) 75%);
    color: white;
    height: 40px;
    width: 100px;
    margin-top: 0px;
    margin-left: 0px;
    animation-name: move_flash_sidebar;
    animation-duration: 4s;
    animation-iteration-count: 1;
}
@keyframes move_flash_sidebar {
    0% {transform: translateX(0)}
    100% {transform: translateX(100%)}
    } */


.menu-text-align{
    margin-top: 6px;
}
.nav-text-sub {
    display: flex;
    padding-left: 0px;
    font-size: 14px;
    list-style: none;
    color: white;
    text-decoration: none;
}
.nav-text-sub.active {
    background: #292929;
    /* border-left: 3px solid #632ce4; */
    border-left: 3px solid #27cdff;
    margin-left: -33px;
}


a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}

.nav-bar-menu {
    display: flex;
    margin-left: 20px;
    margin-bottom: 1px;
    cursor: pointer;
    z-index: 11;
        
}
.nav-bar-menu a {
 height: 30px;
}
.nav-bar-menu span {
    padding-right: 20px;
}
