@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.welcome {
    display: flex;
    justify-content: flex-end;
    color: rgb(78, 78, 78);
    height: 18px;
    /* background-color:#060b26; */
  }
.logout-icon {
    color: rgb(78, 78, 78);
    margin: 0rem 0.2rem 0rem 0.4rem;
  }
  /* #navbar-flash-db {
    background-color: #181818;
    
    height: 35px;
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    animation-name: move_flash_db;
    margin-left: 0px;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-delay: 2s;
    z-index: 34;
    animation-fill-mode: forwards;
} */
/* @keyframes move_flash_db {
    0% {transform: scaleY(1);}
    90% {transform: scaleY(0.0);}
    100% {transform: scaleY(0.0);}
} */
#logdb {
    position: absolute;
    padding-left: 90%;
    padding-top: 6px;
}
.navbar {
    background-color: #181818;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 0px;
    border-top: 4px solid;
    /* border-top-color: rgb(99, 44, 228); */
    border-top-color: #27cdff;
}
/* .navbar-flash {
        background-color: rgb(255, 255, 255);
        background-image: linear-gradient(to right,            
            #27cdff 25%,
            rgba(255, 255, 255, 0.1) 50%,            
            #27cdff 75%);
        color: white;
        height: 4px;
        width: 90%;
        margin-top: -40px;
        margin-left: -130px;
        animation-name: move_flash;
        animation-duration: 30s;
        animation-iteration-count: infinite;
}
@keyframes move_flash {
    0% {transform: translateX(0)}
  20% {transform: translateX(250%)}
  40% {transform: translateX(0)}
  49% {transform: translateX(0)}
  69% {transform: translateX(250%)}
  89% {transform: translateX(0)}
} */

.menu-bars {
    margin: 0.4rem 0rem 0rem 1rem;
    font-size: 2rem;
    background: none;
    z-index:30;
}

.nav-menu {
    background-color: #181818;
    opacity: 0.97;
    width: 200px;
    height: 90vh;
    display: flex;
    justify-content: left;
    position: fixed;
    top: 69px;
    left: -100%;
    transition: 850ms;
    z-index:10;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}
.nav-text svg {
    margin: 10px 10px 5px 10px;
}
.nav-text {
    display: flex;
    font-size: 18px;
    list-style: none;
    color: white;
    text-decoration: none;
    margin-left: -40px;
}
.nav-text.active {
    width: 200px; height: 40px;
    background: rgb(41, 41, 41);
    background-size: 100% 100%;
    /* border-left: 5px solid #632ce4; */
    border-left: 5px solid #27cdff
}
/* .nav-text-flash{
    position: relative;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(to right,
        rgba(41, 41, 41, .9) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(41, 41, 41, .9) 75%);
    color: white;
    height: 40px;
    width: 100px;
    margin-top: 0px;
    margin-left: 0px;
    animation-name: move_flash_sidebar;
    animation-duration: 4s;
    animation-iteration-count: 1;
}
@keyframes move_flash_sidebar {
    0% {transform: translateX(0)}
    100% {transform: translateX(100%)}
    } */


.menu-text-align{
    margin-top: 6px;
}
.nav-text-sub {
    display: flex;
    padding-left: 0px;
    font-size: 14px;
    list-style: none;
    color: white;
    text-decoration: none;
}
.nav-text-sub.active {
    background: #292929;
    /* border-left: 3px solid #632ce4; */
    border-left: 3px solid #27cdff;
    margin-left: -33px;
}


a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}

.nav-bar-menu {
    display: flex;
    margin-left: 20px;
    margin-bottom: 1px;
    cursor: pointer;
    z-index: 11;
        
}
.nav-bar-menu a {
 height: 30px;
}
.nav-bar-menu span {
    padding-right: 20px;
}

/* COOKIES CSS */

.popup-cookies {
  display: none;
}

.popup-cookies.active {
  display: block;
  position: fixed;
  justify-content: center;
  z-index: 11;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-text.active {
  display: block;
  z-index: 12;
  padding: 20px 20px 0px 20px;
  width: 400px;
  height: 400px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(255, 255, 255);
}

.popup-cookies.active img {
  float: right;
  justify-content: center;
}

.popup-button.active {
  display: inline-block;
  position: relative;
  z-index: 13;
  padding: 15px;
  margin: 20px 10px 0px 20px;
  left: 200;
  top: 100;
  cursor: pointer;
  border: solid 1px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(180, 180, 180);
  
}

/* END COOKIES CSS */

.form-first {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
  color: rgb(90, 90, 90);
}

.input-login, .button-login {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  outline: none;
}

/* .welcom {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #53565A;
  } */

.form-first {
  display: block;
  position: relative;
}

.form-first:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #e4e4e4, #888787);
}

.form-first .form-inner {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px;
  z-index: 2;
}

.form-first .form-inner h2 {
  color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}

.form-first .form-inner .form-group {
  display: block;
  width: 300px;
  margin-bottom: 15px;
}

.form-inner .form-group label {
  display: block;
  color: #666;
  font-size: 12px;
  margin-bottom: 5px;
  transition: 0.4s;
}

.form-inner .form-group:focus-within label {
  color: rgb(0, 0, 110);
  font-weight: bolder;
}

.error {
  color: red;
  font-size: 12px;
  margin-left: 7px;
}

.form-first .form-inner .form-group input {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #eeeded;
  border-radius: 8px;
  transition: 0.4;
  border-color: #fff;
}

.form-first .form-inner .from-group input:focus {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-color: #fff;
}

.form-first .form-inner input[type="submit"], .welcome button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #b9b9b9 50%, #b9b9b9 50%, #b9b9b9);
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.form-first .form-inner input[type="submit"]:hover, .welcome button:hover {
  background-position: 100% 0%;
}

/* .welcome {
    width: 100%;
    max-width: 480px;
    background-color: #fff;
    padding: 15px 30px;
  }
  
  .welcome h2 {
    color: #888;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 3px;
  }
  
  .welcome h2 span {
    columns: #FE4880;
    font-weight: 700;
  }
   */
.form-container {
  margin: 1px auto;
  width: 99%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  top: 0px;
  border-radius: 10px;
  height: 800px;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
}

.collapse-button, .form-input-btn, .sorting-buttons {
  border-radius: 2px;
  background: linear-gradient( 90deg, rgb(39, 176, 255) 0%, rgb(0, 232, 236) 100%);
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  /* filter: drop-shadow(0 0 5px rgb(131, 131, 131)); */
  border: solid 1px #a7a7a7;
}

.collapse-button:hover, .form-input-btn:hover, .sorting-buttons:hover {
  cursor: pointer;
  background: linear-gradient( 90deg, rgb(39, 143, 255) 0%, rgb(12, 99, 250) 100%);
  transition: all 0.4s ease-out;
}

.collapse-button {
  width: 220px;
  height: 29px;
  margin-top: 0px;
}

.collapse-content.collapsed {
  display: none;
}

.collapse-content.expanded {
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  text-align: justify;
  position: absolute;
  top: 555px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  -webkit-filter: drop-shadow(0 0 30px #fff);
          filter: drop-shadow(0 0 30px #fff);
  z-index: 99;
}

/* .collapse-content.expanded {
    display: block;
  } */

/* .anleitung-window {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    position: center;
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
    filter: drop-shadow(0 0 30px #fff);
  } */

.form {
  position: absolute;
  /* top: 50%;
    left: 50%;
    transform: translate(50%, 50%); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

/* .form-content {
    border-radius: 10px 10px 10px 10px;
    position: center;
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  } */

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
}

.form-input-select {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 104.5%;
  border: none;
  color: #595959;
  font-size: 12px;
}

.form-input::-webkit-input-placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input:-ms-input-placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-inputs-status-pictures {
  display: flex;
  padding-left: 41px;
}

.form-input-status [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */

.form-input-status [type="radio"]+img {
  cursor: pointer;
  margin-right: 20px;
  height: 123px;
}

.form-input-pictures {
  display: block;
  padding-left: 3px;
  margin-top: 24px;
  outline: none;
  border-radius: 2px;
  height: 124px;
  width: 70%;
  border: none;
  color: #595959;
  background-color: white;
  font-size: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.form-input-pictures [type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */

.form-input-pictures [type="checkbox"]+img {
  cursor: pointer;
  margin: 3px;
  /* padding-right: 5px;
    padding-bottom: 3px; */
  height: 50px;
  width: 50px;
  border-radius: 20%;
}

/* CHECKED STYLES */

.form-input-pictures [type="checkbox"]:checked+img {
  /* outline: 3px solid #f00; */
  box-shadow: 0 0 0pt 2pt red;
}

.button-logout {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #b9b9b9 50%, #b9b9b9 50%, #b9b9b9);
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
}

.form-success {
  color: white;
  position: absolute;
  top: 400%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.success-window {
  color: white;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 350px;
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  -webkit-filter: drop-shadow(0 0 30px #fff);
          filter: drop-shadow(0 0 30px #fff);
  z-index: 100;
  animation: fadeIn 7s;
}

@keyframes fadeIn {  
  0% {opacity: 0;}
  10% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

.success-window-hidden {
  display: none;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

/* STYLES FOR REPAIRS LIST */

.sorting-buttons-box {
  margin: 12px 10px -10px 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.sorting-buttons {
  margin: 5px 5px 0px 5px;
}

.repair-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.repair {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
  display: block;
  /* font-size: 12px; */
  justify-content: center;
  margin-bottom: -20px;  
  margin-left: -20px;
  padding: 5px 5px;
  width: 99%;
}

.repair-list {
  background: #e8e8e8;
  border-radius: 4px;
  max-width: 400px;
  border-top: 3px solid #bbb;
}

.solid {
  /* border-bottom: 1px solid black; */
  border-top: 3px solid #bbb;
}

.label {
  color: #bbb;
  /* background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  font-size: 12px; */
  padding-right: 3px;
}

.value {
  color: black;
  padding-right: 15px;
}

.repairs-pictures {
  padding: 1px;
  height: 50px;
  width: 50px;
  border-radius: 20%;
}

.container-repair-pictures ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.repair-status {
  height: 50px;
}

.del-confirm-repair-btn {
  cursor: pointer;
  width: 100px;
  height: 22px;
  margin-right: 6px;
  margin-left: 6px;
  padding-bottom: 6px;
  -webkit-filter: drop-shadow(0 0 2px rgb(122, 122, 122));
          filter: drop-shadow(0 0 2px rgb(122, 122, 122));
}

.delete-repair-btn {
  cursor: pointer;
  display: flex-end;
  /* flex-direction: column; */
  justify-content: flex-end;
  width: 26px;
  height: 22px;
  padding-bottom: 6px;
  -webkit-filter: drop-shadow(0 0 2px rgb(122, 122, 122));
          filter: drop-shadow(0 0 2px rgb(122, 122, 122));
}

.form-sample-data {
  display: block;
  width: 300px;
}
.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

/* GENERAL */

* {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    position: relative;
}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid lightgray;
    background: white;
}

/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 90%;
    background: white;
    border: 1px solid lightgray;
    height: auto;
    margin: 0 auto;
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid lightgray;
}

.calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
}

.calendar .header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: dodgerblue;
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: gray;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid lightgray;
}

.calendar .body .cell {
    position: relative;
    height: 6em;
    border-right: 1px solid lightgray;
    overflow: hidden;
    cursor: pointer;
    background: white;
    transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
    background: whitesmoke;
    transition: 0.5s ease-out;
}

.calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #494949 0%, #ebebeb 40%);
    border-image-slice: 1;
}

.calendar .body .row {
    border-bottom: 1px solid lightgray;
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}

.calendar .body .cell:hover .number, .calendar .body .selected .number {
    visibility: hidden;
}

.calendar .body .disabled {
    color: lightgray;
    pointer-events: none;
}

.calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: rgb(96, 96, 255);
    opacity: 0;
    font-size: 5em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg {
    opacity: 0.2;
    transition: .5s ease-in;
}

.calendar .body .column {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
}

/* Form to add */

.form-open {
    padding: 10px;
    opacity: 0;
    height: 0;
    transition: height 1s ease-in-out;
    /* filter: drop-shadow(0 0 10px rgb(109, 109, 109)); */
}

.form-open active {
    display: inline-block;
    justify-content: flex-end;
    opacity: 1;
    height: 100px;
    transition: height 1s ease-in-out;
}

/* CalendarDienstList */
.diensts-list {
    /* display: flex; */
    flex-direction: row;
    position: fixed;
    width: 99%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.93);
    background-position: fixed;
    top: 70px;
    left: 1%;
}
.close-x{
    display: flex;
    padding: 2% 5% 0 5%;
    color: white;
    z-index: 20;
    padding-top: 30px;
    cursor: pointer;
}
.item-dienstslist{
border: rgb(255, 255, 255) solid 3px;
margin: 15px;
background-color: lightgrey;
}
.card-header {
background-color: grey;
text-align: center;

}
.card-body {
    margin: 2px;
    text-align: center;
}
.label {
    color: black;
}

  .form-add-container {
      padding-left: 3%;
      padding-right: 3%;
}
  .formadd-grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1px;
    background-color: #929292;
    padding: 1px;
    /* width: 90%; */
  }
  
  .formadd-grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 2px 0;
  }
  
  .formadd-item1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .formadd-item2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .formadd-item5 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
.invalid-feedback {
    color: red;
    font-size: small;
}
  
.button-form-add {
    padding: 1px 5px 3px 5px;
    text-decoration: none;
    font-size: 12px;
    border-radius: 3px;
    background-color: white;
    color: black;
    border: 2px solid #a8a8a8;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    margin: 5px 0 5px 200px;
  }
  .button-form-add:hover {
    background-color: #e7e7e7;
  }
  .button-form-add-end {
    padding: 1px 5px 3px 5px;
    text-decoration: none;
    font-size: 12px;
    border-radius: 3px;
    background-color: white;
    color: black;
    border: 2px solid #a8a8a8;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    margin: 5px 0 5px 0px;
  }
  .button-form-add-end:hover {
    background-color: #e7e7e7;
  }

.umleitungen-head {
  display: flex;
  background-color: yellow;
}

.form-label-um {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 0px;
  color: black;
}

.form-input-um {
  display: block;
  padding-left: 10px;
  outline: solid;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
}

.form-input-um-select {
  display: block;
  padding-left: 10px;
  outline: solid;
  border-radius: 2px;
  height: 40px;
  width: 100px;
  border: none;
  color: #595959;
  font-size: 12px;
}

.umleitung-liste {
  display: flex;
}

/* .portfolio-flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  background-color: DodgerBlue;
}

.portfolio-flex-container>div {
  width: 200px;
  background-color: #f1f1f1;
  margin: 10px;
  padding: 10px;
  font-size: 15px;
} */

.portfolio-list-text {
  list-style-type: circle;
}

.portfolio-more-button {
  cursor: pointer;
  color: #f1f1f1;
  width: 100%;
  height: 30px;
  margin-left: -16px;
  padding: 4px 0 0px 14px;
  background: rgb(78, 78, 78);
  background-size: 100% 100%;
  border-left: 5px solid DodgerBlue;
}

.portfolio-project {
  background-color: #f1f1f1;
  opacity: 1;
  width: 90%;
  height: 0;
  display: flex;
  justify-content: left;
  position: absolute;
  top: 120px;
  left: -100%;
  padding: 10px;
  transition: 850ms;
  z-index: 10;
  -webkit-filter: drop-shadow(0 0 10px rgb(109, 109, 109));
          filter: drop-shadow(0 0 10px rgb(109, 109, 109));
}

.portfolio-project.active {
  height: 90%;
  left: 2px;
  transition: 350ms;
}

.portfolio-close-button {
  position: relative;
  cursor: pointer;
  color: #f1f1f1;
  width: 100px;
  height: 30px;
  margin-left: 15px;
  padding: 4px 0 0px 14px;
  background: rgb(78, 78, 78);
  background-size: 100% 100%;
  border-left: 5px solid DodgerBlue;
}

.verse-stack {
  display: inline;
}
.verse-stack>* {
  margin-bottom: 1em;
}
.content-header {
  width: 70vw;
  margin: 0rem auto;
  padding: 0.5em;
  box-shadow: 0 0 3rem rgba(0, 0, 0.1, alpha);
  background: white;
}

.col-header {
  padding: 0.5em;
  background: rgba(219, 219, 219, 0.1);
  border: 3px solid rgba(0, 0, 0, 0.2);
}

.grid-ish {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
}

.grid-ish>* {
  flex: 1 1 33%;
  /* flex: 0 1 33%; */
  flex: 1 1 10em;
}

.content-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.content-sidebar>*:nth-child(1) {
  flex: 1 1 70%;
  min-width: 15ch;
}

.content-sidebar>*:nth-child(2) {
  flex: 1 1 30%;
}

.content {
  width: 70vw;
  margin: 0rem auto;
  padding: 1em;
  box-shadow: 0 0 3rem rgba(0, 0, 0.1, alpha);
  background: white;
}

.col {
  padding: 1em;
  background: rgba(0, 0, 0, 0.1);
  border: 3px solid rgba(0, 0, 0, 0.2);
}

.umleitung-main {
  display:inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.umleitung-card {
  width: 200px; 
  height: 200px;
  border: grey solid 3px;
  margin: 5px;
  	overflow: hidden;
  	text-overflow: ellipsis;
    transition: width 2s, transform 1s;
}
.umleitung-card.active {
  width: 90%;
  height: 300px;
  border: grey solid 3px;
  margin: 5px;
  	overflow: hidden;
  	text-overflow: ellipsis;
    transition: width 2s, transform 1s;
}
.flex-container {
  display: flex;
  justify-content: center;
}

.flex-container>div {
  background-color: #f1f1f1;
  /* width: 100px; */
  margin: 5px;
  text-align: center;
  line-height: 50px;
  /* font-size: 30px; */
}

.text {
  align-self: center;
  text-align: center;
}

.start-screen.active {
  background-color: #f1f1f1;
  opacity: 1;
  width: 70%;
  height: 40%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10%;
  left: 5%;
  padding: 10px;
  transition: 850ms;
  z-index: 10;
  -webkit-filter: drop-shadow(0 0 10px rgb(109, 109, 109));
          filter: drop-shadow(0 0 10px rgb(109, 109, 109));
}

.start-screen {
  display: flex;
  position: absolute;
  height: 0%;
  width: 0%;
  transition: 850ms;
  left: -100%;
}

.button-clue {
  display: inline-block;
  position: relative;
  /* z-index: 13; */
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(255, 118, 6);
  font-size: xx-large;
}

.button {
  display: inline-block;
  position: relative;
  /* z-index: 13; */
  padding: 15px;
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(180, 180, 180);
}

.score {
  display: flex;
  justify-content: flex-start;
  margin: 10px 10px 10px 10px;
  font-weight: bold;
}

.play-box {
  margin-top: -30px;
}

#field {
  display: flex;
  justify-content: center;
  width: 500px;
  height: 300px;
  background-color: lightgray;
  border: 4px, solid;
  position: relative;
}

#ball {
  /* width: 9px;
    height: 9px; */
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 30px;
  top: 250px;
  z-index: 15;
}

#rocket {
  width: 9px;
  height: 70px;
  background-color: rgb(129, 129, 129);
  position: absolute;
  left: 110px;
  top: 300px;
  z-index: 9;
}
.container {
    margin-top: 0px;
}
    #busStops {
        width: 50%;
        height: 80px;
        position: relative;
        margin-bottom: 8px;
        margin-left: -140px;
      }
  
      #road {
        width: 600px;
        height: 4px;
        position: absolute;
        bottom: -4px;
        background-color: black;
      }
  
      #busMirror {
        width: 30px;
        height: 30px;
        background-color: black;
        /*background-size: cover;*/
        -webkit-clip-path: polygon(21% 10%, 15% 0%, 23% 0, 35% 21%, 43% 29%, 47% 40%, 49% 53%, 47% 64%, 39% 73%, 32% 26%);
                clip-path: polygon(21% 10%, 15% 0%, 23% 0, 35% 21%, 43% 29%, 47% 40%, 49% 53%, 47% 64%, 39% 73%, 32% 26%);
        left: 166px;
        bottom: 18px;
        border-radius: 2px;
        position: absolute;
        animation-name: busMirror;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        /* animation-play-state: paused; */
        /* animation-play-state: running; */
      }
      
      @keyframes busMirror {
        0% {
          left: 166px;
          bottom: 18px;
        }
  
        20% {
          transform-origin: top;
          transform: skew(0deg, 0deg);
        }
  
        23% {
          transform-origin: top;
          transform: skew(-40deg, 0deg);
        }
  
        30% {
          left: 467px;
          bottom: 18px;
        }
  
        50% {
          left: 487px;
          bottom: 18px;
        }
  
        53% {
          transform-origin: top;
          transform: skew(-5deg, 0deg);
        }
  
        60% {
          left: 467px;
          bottom: 18px;
        }
  
        63% {
          transform-origin: top;
          transform: skew(-35deg, 0deg);
        }
  
        65% {
          left: 467px;
          bottom: 14px;
        }
  
        70% {
          transform-origin: top;
          transform: skew(-15deg, 0deg);
        }
  
        74% {
          left: 467px;
          bottom: 14px;
        }
  
        76% {
          left: 472px;
          bottom: -7px;
        }
  
        88% {
          transform-origin: bottom;
          transform: rotate(-80deg);
        }
  
        90% {
          left: 480px;
          bottom: 4px;
        }
  
        100% {
          transform-origin: bottom;
          transform: rotate(-80deg);
        }
  
        100% {
          left: 480px;
          bottom: 4px;
        }
      }
  
     #bus {
        width: 180px;
        height: 70px;
        /*clip-path: polygon(15% 80%, 20% 80%, 23% 70%, 31% 71%, 34% 81%, 46% 81%, 60% 81%, 62% 71%, 71% 72%, 73% 82%, 90% 82%, 91% 75%, 89% 72%, 89% 55%, 87% 27%, 76% 25%, 74% 22%, 61% 21%, 59% 24%, 30% 25%, 29% 21%, 19% 20%, 15% 25%, 7% 25%, 3% 31%, 2% 57%, 3% 73%, 2% 80%, 3% 81%, 6% 81%, 11% 81%);*/
  
        -webkit-clip-path: polygon(71% 84%, 72% 77%, 79% 79%, 81% 95%, 82% 95%, 82% 41%, 88% 41%, 88% 94%, 89% 94%, 89% 41%, 95% 41%, 95% 95%, 98% 94%, 98% 87%, 97% 85%, 97% 71%, 96% 41%, 96% 35%, 96% 27%, 82% 24%, 80% 22%, 75% 21%, 74% 24%, 24% 22%, 23% 19%, 17% 18%, 16% 21%, 7% 23%, 5% 26%, 5% 32%, 15% 35%, 15% 63%, 8% 62%, 8% 40%, 9% 35%, 5% 33%, 5% 64%, 5% 77%, 4% 81%, 5% 90%, 13% 93%, 16% 93%, 16% 40%, 21% 40%, 21% 92%, 22% 92%, 22% 40%, 28% 40%, 28% 93%, 30% 93%, 32% 77%, 38% 78%, 40% 94%, 48% 94%, 47% 70%, 30% 70%, 30% 35%, 46% 35%, 46% 69%, 48% 94%, 48% 40%, 54% 40%, 54% 95%, 55% 95%, 55% 40%, 61% 40%, 61% 94%, 62% 94%, 62% 35%, 72% 35%, 72% 67%, 63% 67%, 73% 67%, 73% 35%, 81% 36%, 81% 67%, 75% 67%, 62% 68%, 62% 94%, 71% 94%);
  
                clip-path: polygon(71% 84%, 72% 77%, 79% 79%, 81% 95%, 82% 95%, 82% 41%, 88% 41%, 88% 94%, 89% 94%, 89% 41%, 95% 41%, 95% 95%, 98% 94%, 98% 87%, 97% 85%, 97% 71%, 96% 41%, 96% 35%, 96% 27%, 82% 24%, 80% 22%, 75% 21%, 74% 24%, 24% 22%, 23% 19%, 17% 18%, 16% 21%, 7% 23%, 5% 26%, 5% 32%, 15% 35%, 15% 63%, 8% 62%, 8% 40%, 9% 35%, 5% 33%, 5% 64%, 5% 77%, 4% 81%, 5% 90%, 13% 93%, 16% 93%, 16% 40%, 21% 40%, 21% 92%, 22% 92%, 22% 40%, 28% 40%, 28% 93%, 30% 93%, 32% 77%, 38% 78%, 40% 94%, 48% 94%, 47% 70%, 30% 70%, 30% 35%, 46% 35%, 46% 69%, 48% 94%, 48% 40%, 54% 40%, 54% 95%, 55% 95%, 55% 40%, 61% 40%, 61% 94%, 62% 94%, 62% 35%, 72% 35%, 72% 67%, 63% 67%, 73% 67%, 73% 35%, 81% 36%, 81% 67%, 75% 67%, 62% 68%, 62% 94%, 71% 94%);
  
        border-radius: 2px;
        background-color: green;
        position: absolute;
        bottom: 0px;
        animation-name: bus;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes bus {
        0% {
          left: 0px;
          bottom: 0px;
        }
  
        30% {
          left: 300px;
          bottom: 0px;
        }
  
        50% {
          left: 320px;
          bottom: 0px;
        }
  
        60% {
          left: 300px;
          bottom: 0px;
        }
  
        65% {
          left: 300px;
          bottom: -4px;
        }
  
        100% {
          left: 300px;
          bottom: -4px;
        }
      }
  
      #busWheelRear {
        border-radius: 25px;
        border: 4px solid black;
        width: 13px;
        height: 13px;
        position: absolute;
        bottom: 0px;
        left: 56px;
        animation-name: busWheelRear;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        /* animation-play-state: paused; */
        animation-play-state: running;
      }
  
      @keyframes busWheelRear {
        0% {
          left: 56px;
        }
  
        30% {
          left: 357px;
        }
  
        50% {
          left: 376px;
        }
  
        60% {
          left: 356px;
        }
  
        65% {
          left: 356px;
        }
  
        100% {
          left: 356px;
        }
      }
  
      #busWheelFront {
        border-radius: 25px;
        border: 4px solid black;
        width: 13px;
        height: 13px;
        position: absolute;
        bottom: 0px;
        left: 130px;
        animation-name: busWheelFront;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes busWheelFront {
        0% {
          left: 130px;
        }
  
        30% {
          left: 429px;
        }
  
        50% {
          left: 450px;
        }
  
        60% {
          left: 430px;
        }
  
        65% {
          left: 430px;
        }
  
        100% {
          left: 430px;
        }
      }
  
      #busSign {
        width: 3px;
        height: 70px;
        background-color: rgb(2, 105, 2);
        position: absolute;
        left: 450px;
        bottom: 0px;
        animation-name: busSign;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes busSign {
        20% {
          transform: skew(-1deg, 0deg);
        }
  
        23% {
          transform: skew(-22deg, 0deg);
        }
  
        100% {
          transform: skew(-22deg, 0deg);
        }
      }
  
      #busSignUp {
        width: 25px;
        height: 25px;
        background-color: goldenrod;
        color: green;
        border: green;
        border-style: solid;
        font-size: 15px;
        text-align: center;
        border-radius: 100%;
        position: absolute;
        left: 428px;
        bottom: 55px;
        animation-name: busSignUp;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
  
      @keyframes busSignUp {
        20% {
          left: 428px;
          bottom: 55px;
        }
  
        22% {
          left: 439px;
          bottom: 57px;
        }
  
        100% {
          left: 439px;
          bottom: 57px;
        }
      }
    
