.form-container {
  margin: 1px auto;
  width: 99%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  top: 0px;
  border-radius: 10px;
  height: 800px;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
}

.collapse-button, .form-input-btn, .sorting-buttons {
  border-radius: 2px;
  background: linear-gradient( 90deg, rgb(39, 176, 255) 0%, rgb(0, 232, 236) 100%);
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  /* filter: drop-shadow(0 0 5px rgb(131, 131, 131)); */
  border: solid 1px #a7a7a7;
}

.collapse-button:hover, .form-input-btn:hover, .sorting-buttons:hover {
  cursor: pointer;
  background: linear-gradient( 90deg, rgb(39, 143, 255) 0%, rgb(12, 99, 250) 100%);
  transition: all 0.4s ease-out;
}

.collapse-button {
  width: 220px;
  height: 29px;
  margin-top: 0px;
}

.collapse-content.collapsed {
  display: none;
}

.collapse-content.expanded {
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  text-align: justify;
  position: absolute;
  top: 555px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  filter: drop-shadow(0 0 30px #fff);
  z-index: 99;
}

/* .collapse-content.expanded {
    display: block;
  } */

/* .anleitung-window {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    position: center;
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
    filter: drop-shadow(0 0 30px #fff);
  } */

.form {
  position: absolute;
  /* top: 50%;
    left: 50%;
    transform: translate(50%, 50%); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

/* .form-content {
    border-radius: 10px 10px 10px 10px;
    position: center;
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  } */

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
}

.form-input-select {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 104.5%;
  border: none;
  color: #595959;
  font-size: 12px;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-inputs-status-pictures {
  display: flex;
  padding-left: 41px;
}

.form-input-status [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */

.form-input-status [type="radio"]+img {
  cursor: pointer;
  margin-right: 20px;
  height: 123px;
}

.form-input-pictures {
  display: block;
  padding-left: 3px;
  margin-top: 24px;
  outline: none;
  border-radius: 2px;
  height: 124px;
  width: 70%;
  border: none;
  color: #595959;
  background-color: white;
  font-size: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.form-input-pictures [type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */

.form-input-pictures [type="checkbox"]+img {
  cursor: pointer;
  margin: 3px;
  /* padding-right: 5px;
    padding-bottom: 3px; */
  height: 50px;
  width: 50px;
  border-radius: 20%;
}

/* CHECKED STYLES */

.form-input-pictures [type="checkbox"]:checked+img {
  /* outline: 3px solid #f00; */
  box-shadow: 0 0 0pt 2pt red;
}

.button-logout {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #b9b9b9 50%, #b9b9b9 50%, #b9b9b9);
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
}

.form-success {
  color: white;
  position: absolute;
  top: 400%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.success-window {
  color: white;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 350px;
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  position: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  filter: drop-shadow(0 0 30px #fff);
  z-index: 100;
  animation: fadeIn 7s;
}

@keyframes fadeIn {  
  0% {opacity: 0;}
  10% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

.success-window-hidden {
  display: none;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

/* STYLES FOR REPAIRS LIST */

.sorting-buttons-box {
  margin: 12px 10px -10px 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.sorting-buttons {
  margin: 5px 5px 0px 5px;
}

.repair-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.repair {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
  display: block;
  /* font-size: 12px; */
  justify-content: center;
  margin-bottom: -20px;  
  margin-left: -20px;
  padding: 5px 5px;
  width: 99%;
}

.repair-list {
  background: #e8e8e8;
  border-radius: 4px;
  max-width: 400px;
  border-top: 3px solid #bbb;
}

.solid {
  /* border-bottom: 1px solid black; */
  border-top: 3px solid #bbb;
}

.label {
  color: #bbb;
  /* background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  font-size: 12px; */
  padding-right: 3px;
}

.value {
  color: black;
  padding-right: 15px;
}

.repairs-pictures {
  padding: 1px;
  height: 50px;
  width: 50px;
  border-radius: 20%;
}

.container-repair-pictures ul {
  padding-inline-start: 0;
}

.repair-status {
  height: 50px;
}

.del-confirm-repair-btn {
  cursor: pointer;
  width: 100px;
  height: 22px;
  margin-right: 6px;
  margin-left: 6px;
  padding-bottom: 6px;
  filter: drop-shadow(0 0 2px rgb(122, 122, 122));
}

.delete-repair-btn {
  cursor: pointer;
  display: flex-end;
  /* flex-direction: column; */
  justify-content: flex-end;
  width: 26px;
  height: 22px;
  padding-bottom: 6px;
  filter: drop-shadow(0 0 2px rgb(122, 122, 122));
}

.form-sample-data {
  display: block;
  width: 300px;
}