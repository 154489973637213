/* COOKIES CSS */

.popup-cookies {
  display: none;
}

.popup-cookies.active {
  display: block;
  position: fixed;
  justify-content: center;
  z-index: 11;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-text.active {
  display: block;
  z-index: 12;
  padding: 20px 20px 0px 20px;
  width: 400px;
  height: 400px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(255, 255, 255);
}

.popup-cookies.active img {
  float: right;
  justify-content: center;
}

.popup-button.active {
  display: inline-block;
  position: relative;
  z-index: 13;
  padding: 15px;
  margin: 20px 10px 0px 20px;
  left: 200;
  top: 100;
  cursor: pointer;
  border: solid 1px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(180, 180, 180);
  
}

/* END COOKIES CSS */

.form-first {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
  color: rgb(90, 90, 90);
}

.input-login, .button-login {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

/* .welcom {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #53565A;
  } */

.form-first {
  display: block;
  position: relative;
}

.form-first:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #e4e4e4, #888787);
}

.form-first .form-inner {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px;
  z-index: 2;
}

.form-first .form-inner h2 {
  color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}

.form-first .form-inner .form-group {
  display: block;
  width: 300px;
  margin-bottom: 15px;
}

.form-inner .form-group label {
  display: block;
  color: #666;
  font-size: 12px;
  margin-bottom: 5px;
  transition: 0.4s;
}

.form-inner .form-group:focus-within label {
  color: rgb(0, 0, 110);
  font-weight: bolder;
}

.error {
  color: red;
  font-size: 12px;
  margin-left: 7px;
}

.form-first .form-inner .form-group input {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #eeeded;
  border-radius: 8px;
  transition: 0.4;
  border-color: #fff;
}

.form-first .form-inner .from-group input:focus {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-color: #fff;
}

.form-first .form-inner input[type="submit"], .welcome button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #b9b9b9 50%, #b9b9b9 50%, #b9b9b9);
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.form-first .form-inner input[type="submit"]:hover, .welcome button:hover {
  background-position: 100% 0%;
}

/* .welcome {
    width: 100%;
    max-width: 480px;
    background-color: #fff;
    padding: 15px 30px;
  }
  
  .welcome h2 {
    color: #888;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 3px;
  }
  
  .welcome h2 span {
    columns: #FE4880;
    font-weight: 700;
  }
   */